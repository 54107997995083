<div *ngIf="count > 0" class="full-width flex-normal flex-justify-between align-items-center ap-mt-spacing-3">
  <div class="flex-normal flex-gap-3 align-items-center">
    <span>Show</span>
    <ap-single-dropdown [inputAriaLabel]="'Number of items per page'" [list]="limitList" [adaptivePosition]="true"
      [(ngModel)]="limit" [styleClass]="'compact-dropdown'" (onSelectItem)="selectLimit()" [disabled]="disablePaging"
      [attachedDOM]="'body'"></ap-single-dropdown>
    <span>Items / Page</span>
  </div>
  <ap-pagination [label]="['Previous','Next']" [max]="totalPage" (onChange)="onPageChange($event)"
    [(ngModel)]="page"></ap-pagination>
</div>
