import { Injectable } from '@angular/core';
import { GqlService } from '@core';
import {
  SendEmailInput,
  getEmailStatDetail,
  getEmailTemplateNameList,
  getEmails,
  sendEmail,
  getRelatedRecipientList,
  listTenants,
} from '@core/gql/email-sent.gql';

@Injectable({
  providedIn: 'root',
})
export class EmailSentService {
  constructor(private gqlService: GqlService) {}

  getEmails(input) {
    return this.gqlService.query(getEmails, { input });
  }

  sendEmail(input: SendEmailInput) {
    return this.gqlService.mutate(sendEmail, { input }, { useMultipart: true });
  }

  getTemplateNameList() {
    const input = { offset: 0, orderBy: 'NAME', sortingType: 'ASC' };
    return this.gqlService.query(getEmailTemplateNameList, { input });
  }

  getEmailStatDetail(emailStatKey: number) {
    return this.gqlService.query(getEmailStatDetail, { emailStatKey });
  }

  getRelatedRecipientList(tenantKey: number) {
    return this.gqlService.query(getRelatedRecipientList, { tenantKey });
  }

  listTenants() {
    return this.gqlService.query(listTenants);
  }
}
