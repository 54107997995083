import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  IsDevModeGuard,
  ViewCustomerCanActivateGuard,
  ViewCustomerCanDeactivateGuard,
} from '@core';
import { BkMainLayoutComponent } from '@shared/component/bk-main-layout/bk-main-layout.component';
import { NonLoginContainerComponent } from './shared/component/non-login-container/non-login-container.component';
import { PreLoginContainerComponent } from './shared/component/pre-login-container/pre-login-container.component';
import { ngxPermissionsGuard } from 'ngx-permissions';
import { CusMainLayoutComponent } from '@shared/component/cus-main-layout/cus-main-layout.component';
import { EmailNotificationComponent } from './routes/email-notification/email-notification.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'bookkeeper',
  },
  {
    path: '',
    component: NonLoginContainerComponent,
    children: [
      {
        path: 'email-notification',
        component: EmailNotificationComponent,
        data: {
          fullScreen: true,
        },
      },
      {
        path: 'style-guide',
        canActivate: [IsDevModeGuard],
        loadChildren: () =>
          import('./routes/style-guide/style-guide.module').then(
            (m) => m.StyleGuideModule,
          ),
      },
      {
        path: 'legal',
        loadChildren: () =>
          import('./routes/legal/legal.module').then((m) => m.LegalModule),
      },
      {
        path: 'timeout',
        loadChildren: () =>
          import('./routes/timeout/timeout.module').then(
            (m) => m.TimeoutModule,
          ),
      },
      {
        path: 'integration',
        loadChildren: () =>
          import('./routes/integration/integration.module').then(
            (m) => m.IntegrationModule,
          ),
      },
      {
        path: 'server-error',
        loadChildren: () =>
          import(
            './routes/server-error-response/server-error-response.module'
          ).then((m) => m.ServerErrorResponseModule),
      },
      {
        path: 'health-check',
        loadChildren: () =>
          import('./routes/health-check/health-check.module').then(
            (m) => m.HealthCheckModule,
          ),
      },
      {
        path: 'key-lib-version',
        loadComponent: () =>
          import('./routes/key-lib-version/key-lib-version.component').then(
            (m) => m.KeyLibVersionComponent,
          ),
      },
    ],
  },
  {
    path: '',
    component: PreLoginContainerComponent,
    children: [
      {
        path: 'register',
        loadChildren: () =>
          import('./routes/register/register.module').then(
            (m) => m.RegisterModule,
          ),
      },
      {
        path: 'automation',
        loadComponent: () =>
          import(
            './shared/component/automation-test/automation-test.component'
          ).then((m) => m.AutomationTestComponent),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./routes/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'sign',
        loadChildren: () =>
          import('./routes/sign/sign.module').then((m) => m.SignModule),
      },
      {
        path: 'welcome',
        loadChildren: () =>
          import('./routes/welcome/welcome.module').then(
            (m) => m.WelcomeModule,
          ),
      },
    ],
  },
  {
    path: '',
    data: {
      showSidenav: true,
      permissions: {}, // This is for the new ngxPermision that update their code to this: ``!!route && route.data ? (route.data['permissions'] as NgxPermissionsRouterData) : {};``
    },
    canActivate: [AuthGuard, ngxPermissionsGuard],
    canActivateChild: [ngxPermissionsGuard],
    children: [
      {
        path: 'customer',
        component: CusMainLayoutComponent,
        children: [
          {
            path: 'overview',
            loadChildren: () =>
              import('./routes/overview/overview.module').then(
                (m) => m.OverviewModule,
              ),
            data: {
              fullScreen: false,
              permissions: {
                only: ['VIEW_BOOKKEEPING'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'reports',
            loadChildren: () =>
              import('./routes/customers/reports/reports.module').then(
                (m) => m.ReportsModule,
              ),
            data: {
              fullScreen: true,
              permissions: {
                only: ['VIEW_REPORT'],
                redirectTo: '/customer',
              },
            },
          },
          {
            path: 'domo-dashboard',
            loadChildren: () =>
              import('./routes/domo-dashboard/domo-dashboard.module').then(
                (m) => m.DomoDashboardModule,
              ),
            data: {
              fullScreen: true,
              overview: 'Dashboard',
              permissions: {
                only: ['VIEW_DASHBOARD'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'quick-links',
            loadChildren: () =>
              import('./routes/customers/quick-links/quick-links.module').then(
                (m) => m.QuickLinksModule,
              ),
            data: {
              fullScreen: true,
              permissions: {
                only: ['VIEW_QUICK_LINK'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'task-list',
            loadChildren: () =>
              import('./routes/task-list/task-list.module').then(
                (m) => m.TaskListModule,
              ),
            data: {
              fullScreen: true,
              permissions: {
                only: ['VIEW_TASK_LIST'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'member',
            loadChildren: () =>
              import('./routes/member/member.module').then(
                (m) => m.MemberModule,
              ),
            data: {
              fullScreen: true,
              permissions: {
                only: ['USER_MGMT_VIEW_USER'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'profile',
            loadChildren: () =>
              import('./routes/profile/profile.module').then(
                (m) => m.ProfileModule,
              ),
            data: {
              permissions: {
                only: ['VIEW_PROFILE'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'activity-log',
            loadChildren: () =>
              import('./routes/activity-log/activity-log.module').then(
                (m) => m.ActivityLogModule,
              ),
            data: {
              hasBack: false,
              permissions: {
                only: ['CUSTOMER_VIEW_ACTIVITY_LOG'],
                redirectTo: '/',
              },
              fullScreen: true,
            },
          },
          {
            path: 'empty',
            loadChildren: () =>
              import('./routes/empty/empty.module').then((m) => m.EmptyModule),
          },
          {
            path: '',
            redirectTo: 'overview',
            pathMatch: 'full',
          },
          {
            path: ':tenantKey',
            canActivate: [ViewCustomerCanActivateGuard],
            canDeactivate: [ViewCustomerCanDeactivateGuard],
            data: {
              permissions: {}, // This is for the new ngxPermision that update their code to this: ``!!route && route.data ? (route.data['permissions'] as NgxPermissionsRouterData) : {};``
              isBKViewCustomer: true,
            },
            children: [
              {
                path: 'overview',
                loadChildren: () =>
                  import('./routes/overview/overview.module').then(
                    (m) => m.OverviewModule,
                  ),
                data: {
                  // fullScreen: true,
                  permissions: {
                    only: ['VIEW_BOOKKEEPING'],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: 'reports',
                loadChildren: () =>
                  import('./routes/customers/reports/reports.module').then(
                    (m) => m.ReportsModule,
                  ),
                data: {
                  fullScreen: true,
                  permissions: {
                    only: ['VIEW_REPORT'],
                    redirectTo: '/customer',
                  },
                },
              },
              {
                path: 'domo-dashboard',
                loadChildren: () =>
                  import('./routes/domo-dashboard/domo-dashboard.module').then(
                    (m) => m.DomoDashboardModule,
                  ),
                data: {
                  fullScreen: true,
                  permissions: {
                    only: ['VIEW_DASHBOARD'],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: 'quick-links',
                loadChildren: () =>
                  import(
                    './routes/customers/quick-links/quick-links.module'
                  ).then((m) => m.QuickLinksModule),
                data: {
                  fullScreen: true,
                  permissions: {
                    only: ['VIEW_QUICK_LINK'],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: 'task-list',
                loadChildren: () =>
                  import('./routes/task-list/task-list.module').then(
                    (m) => m.TaskListModule,
                  ),
                data: {
                  fullScreen: true,
                  permissions: {
                    only: ['VIEW_TASK_LIST'],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: 'member', // for customer users management VIEW
                loadChildren: () =>
                  import('./routes/member/member.module').then(
                    (m) => m.MemberModule,
                  ),
                data: {
                  permissions: {
                    only: ['USER_MGMT_VIEW_USER'],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: 'profile',
                loadChildren: () =>
                  import('./routes/profile/profile.module').then(
                    (m) => m.ProfileModule,
                  ),
                data: {
                  permissions: {
                    only: ['VIEW_PROFILE'],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: 'activity-log',
                loadChildren: () =>
                  import('./routes/activity-log/activity-log.module').then(
                    (m) => m.ActivityLogModule,
                  ),
                data: {
                  hasBack: false,
                  permissions: {
                    only: ['CUSTOMER_VIEW_ACTIVITY_LOG'],
                    redirectTo: '/',
                  },
                  fullScreen: true,
                },
              },
              {
                path: 'empty',
                loadChildren: () =>
                  import('./routes/empty/empty.module').then(
                    (m) => m.EmptyModule,
                  ),
              },
            ],
          },
          {
            path: '**',
            redirectTo: 'overview',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'bookkeeper',
        component: BkMainLayoutComponent,
        children: [
          {
            path: 'customers',
            loadChildren: () =>
              import('./routes/customers/customers.module').then(
                (m) => m.CustomersModule,
              ),
            data: {
              permissions: {
                only: ['VIEW_DASHBOARD_PWC'],
                redirectTo: '/customer',
              },
              fullScreen: true,
            },
          },
          {
            path: 'member', // for bookkeepers management  VIEW
            loadChildren: () =>
              import('./routes/user-management/user-management.module').then(
                (m) => m.UserManagementModule,
              ),
            data: {
              fullScreen: true,
              permissions: {
                only: ['USER_MGMT_VIEW_USER_PWC'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'profile',
            loadChildren: () =>
              import('./routes/profile/profile.module').then(
                (m) => m.ProfileModule,
              ),
            data: {
              fullScreen: false,
              permissions: {
                only: ['VIEW_PROFILE_PWC'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'email-notification',
            loadChildren: () =>
              import(
                './routes/email-notification/email-notification.module'
              ).then((m) => m.EmailNotificationModule),
            data: {
              overview: 'Notification Preference',
              permissions: {
                only: ['VIEW_PROFILE_PWC'],
                redirectTo: '/',
              },
              fullScreen: true,
            },
          },
          {
            path: 'permission',
            loadChildren: () =>
              import('./routes/permission/permission.module').then(
                (m) => m.PermissionModule,
              ),
            data: {
              permissions: {
                only: ['VIEW_PROFILE_PWC'], // TODO
                redirectTo: '/',
              },
              fullScreen: false,
            },
          },
          {
            path: 'activity-log',
            loadChildren: () =>
              import('./routes/activity-log/activity-log.module').then(
                (m) => m.ActivityLogModule,
              ),
            data: {
              hasBack: false,
              permissions: {
                only: ['USER_MGMT_VIEW_ACTIVITY_LOG_PWC'],
                redirectTo: '/',
              },
              fullScreen: true,
            },
          },
          // {
          //   path: 'quick-links',
          //   pathMatch: 'full',
          //   redirectTo: 'customers',
          // },
          {
            path: 'template-library',
            loadChildren: () =>
              import('./routes/template-library/template-library.module').then(
                (m) => m.TemplateLibraryModule,
              ),
            data: {
              hasBack: false,
              permissions: {
                only: ['CREATE_TEMPLATE_LIBRARY'],
                redirectTo: '/',
              },
              fullScreen: true,
            },
          },
          {
            path: 'task-list',
            loadChildren: () =>
              import('./routes/task-list/task-list.module').then(
                (m) => m.TaskListModule,
              ),
            data: {
              hasBack: false,
              fullScreen: true,
              permissions: {
                only: ['VIEW_TASK_LIST_PWC'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'task-library',
            loadChildren: () =>
              import('./routes/task-library/task-library.module').then(
                (m) => m.TaskLibraryModule,
              ),
            data: {
              hasBack: false,
              fullScreen: true,
              permissions: {
                only: ['VIEW_ALL_TASK_LIBS'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'email-templates',
            loadChildren: () =>
              import('./routes/email-templates/email-templates.module').then(
                (m) => m.EmailTemplatesModule,
              ),
            data: {
              hasBack: false,
              permissions: {
                only: ['MANAGE_EMAIL_TEMPLATE'],
                redirectTo: '/',
              },
              fullScreen: true,
            },
          },
          {
            path: 'mail-box',
            loadChildren: () =>
              import('./routes/email-sent/email-sent.module').then(
                (m) => m.EmailSentModule,
              ),
            data: {
              hasBack: false,
              fullScreen: true,
              permissions: {
                only: ['MANAGE_EMAIL_TEMPLATE', 'SEND_EMAIL'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'cases',
            loadChildren: () =>
              import('./routes/cases/cases.module').then((m) => m.CasesModule),
            data: {
              hasBack: false,
              fullScreen: true,
              permissions: {
                only: ['MANAGE_EMAIL_TEMPLATE'], // TODO update
                redirectTo: '/',
              },
            },
          },
          {
            path: 'customer-groups',
            loadChildren: () =>
              import('./routes/customer-groups/customer-groups.module').then(
                (m) => m.CustomerGroupsModule,
              ),
            data: {
              permissions: {
                only: ['VIEW_DASHBOARD_PWC'],
                redirectTo: '/',
              },
              fullScreen: true,
            },
          },
          {
            path: 'deactivated-customers',
            loadChildren: () =>
              import(
                './routes/deactivated-customers/deactivated-customers.module'
              ).then((m) => m.DeactivatedCustomersModule),
            data: {
              overview: 'Deactivated Customers',
              fullScreen: true,
              permissions: {
                only: ['VIEW_DASHBOARD_PWC'],
                redirectTo: '/',
              },
            },
          },
          {
            path: '',
            redirectTo: 'customers',
            pathMatch: 'full',
          },
        ],
        data: {
          fullScreen: true,
        },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'bookkeeper',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled', // add due to Angular 14 or later version upgrade
      scrollOffset: [0, 64], // [x, y] - adjust scroll offset
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
