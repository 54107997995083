import gql from 'graphql-tag';
import { TaskListFilter } from './task-list.gql';
import { User } from '@models/user.model';

export const createTaskLibrary = gql`
  mutation createTaskLibrary($createTaskLibraryInput: CreateTaskLibraryInput!) {
    createTaskLibrary(input: $createTaskLibraryInput) {
      status
      message
    }
  }
`;

export const updateTaskLibrary = gql`
  mutation updateTaskLibrary($updateTaskLibraryInput: UpdateTaskLibraryInput!) {
    updateTaskLibrary(input: $updateTaskLibraryInput) {
      status
      message
    }
  }
`;

export const updateTaskProgress = gql`
  mutation updateTaskProgress(
    $updateTaskProgressInput: UpdateTaskProgressInput!
  ) {
    updateTaskProgress(input: $updateTaskProgressInput) {
      status
      message
    }
  }
`;

export const getTaskAttachment = gql`
  query getTaskAttachment($taskAttachmentKey: Int!) {
    getTaskAttachment(taskAttachmentKey: $taskAttachmentKey) {
      status
      message
      data {
        taskLibraryKey
        taskAttachmentKey
        fileName
        storageFileName
        attachment {
          name
          base64Content
        }
      }
    }
  }
`;

export const updateTaskProgressList = gql`
  mutation updateTaskProgressList(
    $updateTaskProgressListInput: UpdateTaskProgressListInput!
  ) {
    updateTaskProgressList(input: $updateTaskProgressListInput) {
      status
      message
    }
  }
`;

export const updateTaskProgressListForAppkit4 = gql`
  mutation updateTaskProgressListForAppkit4(
    $updateTaskProgressListInput: UpdateTaskProgressListInput!
  ) {
    updateTaskProgressListForAppkit4(input: $updateTaskProgressListInput) {
      status
      message
    }
  }
`;

export const updateAgnOrRewer = gql`
  mutation updateAgnOrRewer($input: UpdateAgnOrRewerInput!) {
    updateAgnOrRewer(input: $input) {
      status
      message
    }
  }
`;

export const getTaskLibrary = gql`
  query getTaskLibrary($taskLibraryKey: Int!) {
    getTaskLibrary(taskLibraryKey: $taskLibraryKey) {
      status
      message
      data {
        taskLibraryKey
        templateLibraryKey
        name
        description
        content
        visible
        taskAttachments {
          taskAttachmentKey
          taskLibraryKey
          fileName
          storageFileName
          attachment {
            name
            base64Content
          }
        }
      }
    }
  }
`;

export const listAssigneeOrReviewer4Tsks = gql`
  query listAssigneeOrReviewer4Tsks($input: ListAssigneeOrReviewer4TsksInput) {
    listAssigneeOrReviewer4Tsks(input: $input) {
      message
      status
      data {
        tenantKey
        customers {
          userKey
          email
          firstNme
          lastNme
          userType {
            nme
          }
        }
        bkUsers {
          userKey
          email
          firstNme
          lastNme
          userType {
            nme
          }
        }
      }
    }
  }
`;

export const getTaskProgress = gql`
  query getTaskProgress($taskProgressKey: Int!) {
    getTaskProgress(taskProgressKey: $taskProgressKey) {
      status
      message
      data {
        taskLibraryKey
        taskProgressKey
        name
        description
        content
        visible
        category
        taskAttachments {
          taskAttachmentKey
          taskLibraryKey
          fileName
          storageFileName
          attachment {
            name
            base64Content
          }
        }
        frequency
        sendOn
        dueOn
        dueDate
        isPublished
        taskLibType {
          taskLibTypeKey
          name
          displayName
        }
      }
    }
  }
`;

export const getUsersInTaskList = gql`
  query getUsersInTaskList($tenantKey: Int!) {
    getUsersInTaskList(tenantKey: $tenantKey) {
      status
      message
      data {
        bks {
          userKey
          firstNme
          lastNme
          email
          userType {
            userTypeKey
            nme
          }
        }
        customers {
          userKey
          firstNme
          lastNme
          email
          userType {
            userTypeKey
            nme
          }
        }
      }
    }
  }
`;

export const taskListFragment = gql`
  fragment taskListFragment on TaskProgressData {
    name
    description
    visible
    featureDisabled
    isPublished
    dueDate
    isCompleted
    taskProgressKey
    taskLibraryKey
    taskCategory
    unreadCommentsCount
    unreadNotesCount
    frequency
    sendOn
    repeat
    status {
      taskStatusKey
      name
      displayName
    }
    taskLibType {
      taskLibTypeKey
      name
      displayName
    }
    assignee {
      userKey
      firstNme
      lastNme
      email
      userType {
        userTypeKey
        nme
      }
      avatar
    }
    reviewer {
      userKey
      firstNme
      lastNme
      email
      userType {
        userTypeKey
        nme
      }
      avatar
    }
    category
    skipApproval
  }
`;

export const listTaskProgressByIsPubished = gql`
  ${taskListFragment}
  query listTaskProgressByIsPubished(
    $listTaskProgressInput: ListTaskProgressByIsPubishedInput!
  ) {
    listTaskProgressByIsPubished(input: $listTaskProgressInput) {
      status
      message
      data {
        count
        dynamicFilterCounts {
          all
          allFilter
          pending
          complete
          open
          close
          notStarted
          dueToday
          duePast
          dueWithinThree
          dueWithinSeven
          allType
          onboarding
          bookkeeping
          miscellaneous
          preonboarding
        }
        records {
          ...taskListFragment
        }
      }
    }
  }
`;

export const updateTaskStatus = gql`
  mutation updateTaskStatus($updateTaskStatusInput: UpdateTaskStatusInput!) {
    updateTaskStatus(input: $updateTaskStatusInput) {
      status
      message
    }
  }
`;

export const updateSkipApproval = gql`
  mutation updateSkipApproval(
    $updateSkipApprovalInput: UpdateSkipApprovalInput!
  ) {
    updateSkipApproval(input: $updateSkipApprovalInput) {
      status
      message
    }
  }
`;

export const listTaskStatus = gql`
  query listTaskStatus {
    listTaskStatus {
      status
      message
      data {
        taskStatusKey
        name
        displayName
      }
    }
  }
`;

export const confirmNewTaskAssignment = gql`
  mutation confirmNewTaskAssignment(
    $confirmNewTaskInput: [ConfirmNewTaskInput]
  ) {
    confirmNewTaskAssignment(input: $confirmNewTaskInput) {
      status
      message
    }
  }
`;

export const getTenantInfo = gql`
  query getTenantInfo($tenantKey: Int!) {
    getTenant(where: { tenantKey: $tenantKey }) {
      nme
      publishedTaskCount
      taskCount
    }
  }
`;

export const deleteTasks = gql`
  mutation deleteTasks($taskProgressKeys: [Int!]!) {
    deleteTasks(taskProgressKeys: $taskProgressKeys) {
      status
      message
    }
  }
`;

export const updateTaskVisibility = gql`
  mutation updateTaskVisibility(
    $updateTaskVisibilityInput: [UpdateTaskVisibilityInput]
  ) {
    updateTaskVisibility(input: $updateTaskVisibilityInput) {
      status
      message
    }
  }
`;

export interface UpdateTaskVisibilityInput {
  taskProgressKey: number;
  visible: boolean;
}

export interface CreateTaskLibraryInput {
  templateLibraryKey?: number;
  tenantKey?: number;
  name: string;
  description: string;
  content: string;
  taskLibType?: TaskType;
  frequency?: FrequencyType;
  sendOn?: string;
  attachments?: File[];
}

export interface UpdateTaskLibraryInput {
  taskLibraryKey: number;
  taskProgressKey?: number;
  templateLibraryKey?: number;
  name?: string;
  description?: string;
  content?: string;
  atchKeysToBeRmvd?: number[];
  newAtchs?: any[];
  taskLibType?: TaskType;
  frequency?: FrequencyType;
  sendOn?: string;
  visible?: boolean;
}

export interface UpdateTaskProgressInput {
  taskLibraryKey: number;
  taskProgressKey?: number;
  name?: string;
  description?: string;
  content?: string;
  atchKeysToBeRmvd?: number[];
  newAtchs?: any[];
  taskLibType?: TaskType;
  frequency?: FrequencyType;
  sendOn?: string;
}

export interface TaskStatusType {
  taskStatusKey: number;
  name: string;
  displayName: string;
}

export interface UpdateTaskStatusInput {
  taskProgressKey: number;
  taskStatusKey: number;
}

export interface UpdateSkipApprovalInput {
  taskProgressKey: number;
  skipApproval: boolean;
}

export interface UpdateTaskProgress {
  taskProgressKey: number;
  visible?: boolean;
  assignee?: number;
  reviewer?: number;
  dueDate?: Date | string;
  shouldPublish?: boolean;
}

export interface UpdateTaskProgressListInput {
  tenantKey: number;
  isPublished: boolean;
  taskProgressList: UpdateTaskProgress[];
}

export interface ConfirmNewTaskInput {
  taskProgressKey: number;
  taskStatusKey: number;
  visible: boolean;
  assignee: number;
  reviewer: number;
  dueDate: string | Date;
}

export interface ListTaskProgressInput {
  tenantKey: number;
  filterList?: TaskListFilter;
  filterType?: TaskTypeFilter;
  dateMarker: Date;
  isPublished?: boolean;
  limit?: number;
  offset?: number;
  searchText?: string;
}

export interface ListAssigneeOrReviewer4TsksInput {
  tenantKeys: number[];
}

export interface UpdateAgnOrRewerInput {
  assignee: UpdateReviewerAndAssignee[];
  reviewer: UpdateReviewerAndAssignee[];
}

export interface UpdateReviewerAndAssignee {
  taskProgressKey: number;
  userKey: number;
}

export enum TaskFilter {
  ALL = 'ALL',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  DUE_TODAY = 'DUE_TODAY',
  OVERDUE = 'OVERDUE',
  DUE_WITHIN_3_DAYS = 'DUE_WITHIN_3_DAYS',
}

export enum TaskStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  WAITING_ON_CLIENT = 'WAITING_ON_CLIENT',
  ON_HOLD = 'ON_HOLD',
  CLOSED = 'CLOSED',
}

export enum MODE {
  NEW,
  CREATE,
  EDIT,
  PREVIEW,
  SENT,
}

export enum TaskMODE {
  PROTO_TYPE,
  INSTANCE_TYPE,
}

export const TaskStatusList = [
  {
    taskStatusKey: 1,
    name: TaskStatus.NOT_STARTED,
    displayName: 'Not Started',
  },
  {
    taskStatusKey: 2,
    name: TaskStatus.IN_PROGRESS,
    displayName: 'In Progress',
  },
  {
    taskStatusKey: 3,
    name: TaskStatus.READY_FOR_REVIEW,
    displayName: 'Ready for Review',
  },
  {
    taskStatusKey: 4,
    name: TaskStatus.WAITING_ON_CLIENT,
    displayName: 'Waiting on Client',
  },
  {
    taskStatusKey: 5,
    name: TaskStatus.ON_HOLD,
    displayName: 'On Hold',
  },
  {
    taskStatusKey: 6,
    name: TaskStatus.CLOSED,
    displayName: 'Closed',
  },
];

export interface TaskLibrary {
  taskLibraryKey: number;
  templateLibraryKey?: number;
  name: string;
  description: string;
  content: string;
  visible?: boolean;
  taskAttachments: TaskAttachment;
  fileList?: any;
}

export interface TaskAttachment {
  taskAttachmentKey: number;
  taskLibraryKey: number;
  fileName: string;
  storageFileName: string;
  attachment?: File;
}

export interface TaskLibType {
  taskLibTypeKey: number;
  name: TaskType;
  displayName: string;
}

export interface OnboardingTask {
  taskProgressKey?: number;
  taskLibraryKey?: number;
  status?: TaskStatusType;
  name?: string;
  taskLibType?: TaskLibType;
  description?: string;
  visible?: boolean;
  featureDisabled?: boolean;
  skipApproval?: boolean;
  assignee?: User;
  reviewer?: User;
  dueDate?: Date | string;
  isPublished?: boolean;
  isCustomerComment?: boolean;
  unreadCommentsCount?: number;
  unreadNotesCount?: number;
  frequency?: string;
  sendOn?: string;
  checked?: boolean;
  userAssigneeGroups?: any[];
  userReviewerGroups?: any[];
}

export enum TaskType {
  PRE_ONBOARDING = 'PREONBOARDING',
  ONBOARDING = 'ONBOARDING',
  BOOKKEEPING = 'BOOKKEEPING',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export enum TaskTypeFilter {
  ALL = 'ALL',
  PRE_ONBOARDING = 'PREONBOARDING',
  ONBOARDING = 'ONBOARDING',
  BOOKKEEPING = 'BOOKKEEPING',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export enum FrequencyType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  EVERY_2_WEEKS = 'EVERY_2_WEEKS',
  MONTHLY = 'MONTHLY',
  EVERY_OTHER_MONTH = 'EVERY_OTHER_MONTH',
  QUARTERLY = 'QUARTERLY',
}

export enum WEEK_DATA {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export const FrequencyList = [
  { label: 'Daily', value: FrequencyType.DAILY },
  { label: 'Weekly', value: FrequencyType.WEEKLY },
  { label: 'Every 2 weeks', value: FrequencyType.EVERY_2_WEEKS },
  { label: 'Monthly', value: FrequencyType.MONTHLY },
  { label: 'Every other month', value: FrequencyType.EVERY_OTHER_MONTH },
  { label: 'Quarterly', value: FrequencyType.QUARTERLY },
];

export const TaskTypes = [
  { label: 'Pre-onboarding', value: TaskType.PRE_ONBOARDING },
  { label: 'Onboarding', value: TaskType.ONBOARDING },
  { label: 'Bookkeeping', value: TaskType.BOOKKEEPING },
  { label: 'Miscellaneous', value: TaskType.MISCELLANEOUS },
];
